import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';
import axios from 'axios';

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    hoverBackgroundColor: string[];
  }[];
}

const TopCryptoCurrenciesByMarketCap: React.FC = () => {
  const [, setData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: 'Top Crypto Currencies by Market Cap',
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  });

  useEffect(() => {
    const fetchTopCryptoData = async () => {
      try {
        const response = await axios.get('https://api.coingecko.com/api/v3/coins/markets', {
          params: {
            vs_currency: 'usd',
            order: 'market_cap_desc',
            per_page: 5,
            page: 1,
          },
        });
        const coins = response.data;
        const labels = coins.map((coin: any) => coin.name);
        const marketCaps = coins.map((coin: any) => coin.market_cap);
        const backgroundColors = ['#FF6384', '#36A2EB', '#FFCE56', '#FF9F40', '#4BC0C0'];

        setData({
          labels,
          datasets: [
            {
              label: 'Top Crypto Currencies by Market Cap',
              data: marketCaps,
              backgroundColor: backgroundColors,
              hoverBackgroundColor: backgroundColors,
            },
          ],
        });

        if (chartRef.current) {
          new Chart(chartRef.current, {
            type: 'doughnut',
            data: {
              labels,
              datasets: [{
                label: 'Top Crypto Currencies by Market Cap',
                data: marketCaps,
                backgroundColor: backgroundColors,
                hoverBackgroundColor: backgroundColors,
              }]
            },
          });
        }
      } catch (error) {
        console.error('Error fetching top crypto currencies by market cap:', error);
      }
    };

    fetchTopCryptoData();
  }, []);

  const chartRef = useRef<HTMLCanvasElement | null>(null);

  // Ensure to use `data` somewhere in your component to prevent TS6133 error

  return (
    <div className="p-4 bg-white dark:bg-boxdark rounded-sm shadow-default">
      <h4 className="mb-4 text-lg font-semibold text-black dark:text-white">
        Top Crypto Currencies by Market Cap
      </h4>
      <canvas ref={chartRef} width="400" height="400" />
    </div>
  );
};

export default TopCryptoCurrenciesByMarketCap;

