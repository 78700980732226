import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { useAuth } from '../../../../Context/AuthContext'; // Assuming you have an AuthContext
import { db } from '../../../../Firebase'; // Make sure the correct path to your Firebase config

type Crypto = {
  id: string;
  name: string;
  image: string;
  current_price: number;
  price_change_percentage_24h: number;
  market_cap: number;
};

type WatchlistProps = {
  watchlist: string[];
  cryptos: Crypto[];
  onRemoveFromWatchlist: (coinId: string) => void;
};

const Watchlist: React.FC<WatchlistProps> = ({ cryptos, onRemoveFromWatchlist }) => {
  const { currentUser } = useAuth(); // Get the current user from your AuthContext
  const navigate = useNavigate();
  const [userWatchlist, setUserWatchlist] = useState<string[]>([]);

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price).replace(',', ' ');
  };

  const formatMarketCap = (marketCap: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      compactDisplay: 'short',
    }).format(marketCap);
  };

  const handleCoinClick = (coinId: string) => {
    navigate(`/dashboard/coins/${coinId}`);
  };

  const handleRemoveFromWatchlist = async (coinId: string) => {
    try {
      if (!currentUser) return;

      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        watchlist: arrayRemove(coinId)
      });

      setUserWatchlist(prevWatchlist => prevWatchlist.filter(id => id !== coinId));
      onRemoveFromWatchlist(coinId); // Trigger parent component's remove function
    } catch (error) {
      console.error('Error removing from watchlist:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) return;

      const userRef = doc(db, 'users', currentUser.uid);
      const snapshot = await getDoc(userRef);

      if (snapshot.exists()) {
        setUserWatchlist(snapshot.data()?.watchlist || []);
        console.log('Fetched watchlist:', snapshot.data()?.watchlist || []);
      }
    };

    fetchData();
  }, [currentUser]);

  return (
    <div className="rounded-sm border border-stroke bg-white px-2 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-6 xl:px-8 xl:pb-1 w-full">
      <h4 className="mb-4 text-lg font-semibold text-black dark:text-white sm:mb-6 sm:text-xl">
        My Watchlist
      </h4>

      <div className="flex flex-col overflow-x-auto">
        <div className="min-w-full grid grid-cols-8 text-xs sm:text-sm rounded-sm bg-black-2 dark:bg-meta-4">
          <div className="p-2 sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-black">#</h5>
          </div>
          <div className="p-2 sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-black">Coin</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-black">Name</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-black">Price (USD)</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-black">24h Change</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-black">Market Cap</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-black">Actions</h5>
          </div>
        </div>

        {userWatchlist.length === 0 ? (
          <div className="text-center py-4">
            <p className="text-black dark:text-white">No coins in your Watchlist</p>
          </div>
        ) : (
          userWatchlist.map((coinId, index) => {
            const crypto = cryptos.find(crypto => crypto.id === coinId);

            if (!crypto) return null;

            return (
              <div
                className={`min-w-full grid grid-cols-8 text-xs sm:text-sm ${
                  index === userWatchlist.length - 1 ? '' : 'border-b border-stroke dark:border-strokedark'
                }`}
                key={crypto.id}
                onClick={() => handleCoinClick(crypto.id)}
                style={{ cursor: 'pointer' }}
              >
                <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                  <p className="text-black dark:text-white">{index + 1}</p>
                </div>

                <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                  <div className="flex-shrink-0">
                    <img src={crypto.image} alt={crypto.name} className="h-6 w-6 sm:h-8 sm:w-8" />
                  </div>
                </div>

                <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                  <p className="text-black dark:text-white">{crypto.name}</p>
                </div>

                <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4 whitespace-nowrap">
                  <p className="text-black dark:text-white">{formatPrice(crypto.current_price)}</p>
                </div>

                <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                  <p className={crypto.price_change_percentage_24h >= 0 ? 'text-green-500' : 'text-red-500'}>
                    {crypto.price_change_percentage_24h.toFixed(2)}%
                  </p>
                </div>

                <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                  <p className="text-black dark:text-white">{formatMarketCap(crypto.market_cap)}</p>
                </div>

                <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveFromWatchlist(crypto.id); // Use local remove function
                    }}
                    className="bg-red-500 text-white px-3 py-1 rounded"
                  >
                    Remove
                  </button>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Watchlist;






