import React, { useState, useEffect } from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import DefaultLayout from '../layout/DefaultLayout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import Select from 'react-select';
import { FaExchangeAlt } from 'react-icons/fa';

interface CryptoInfo {
  name: string;
  image: { large: string };
  market_data: {
    market_cap?: { usd?: number };
    total_volume?: { usd?: number };
    circulating_supply?: number;
  };
  description: { en: string };
}

const Profile: React.FC = () => {
  const [crypto, setCrypto] = useState<{ value: string; label: string }>({ value: 'bitcoin', label: 'Bitcoin' });
  const [fiat, setFiat] = useState<{ value: string; label: string }>({ value: 'usd', label: 'USD' });
  const [cryptoAmount, setCryptoAmount] = useState<number>(1);
  const [fiatAmount, setFiatAmount] = useState<number>(0);
  const [cryptoInfo, setCryptoInfo] = useState<Partial<CryptoInfo>>({});
  const [cryptoOptions, setCryptoOptions] = useState<{ value: string; label: string }[]>([]);
  const [fiatOptions] = useState<{ value: string; label: string }[]>([
    { value: 'usd', label: 'USD' },
    { value: 'eur', label: 'EUR' },
    { value: 'gbp', label: 'GBP' },
    { value: 'zar', label: 'ZAR' },
    // Add other fiat currencies here
  ]);

  useEffect(() => {
    fetchCryptocurrencies();
    fetchConversionRate();
    fetchCryptoInfo();
  }, [crypto, fiat, cryptoAmount]);

  const fetchCryptocurrencies = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/coins/markets', {
        params: {
          vs_currency: 'usd',
          per_page: 300,
          order: 'market_cap_desc',
        },
      });
      const options = response.data.map((coin: any) => ({
        value: coin.id,
        label: coin.name,
      }));
      setCryptoOptions(options);
    } catch (error) {
      console.error('Error fetching cryptocurrencies:', error);
    }
  };

  const fetchConversionRate = async () => {
    try {
      const response = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${crypto.value}&vs_currencies=${fiat.value}`);
      setFiatAmount(response.data[crypto.value]?.[fiat.value] * cryptoAmount || 0);
    } catch (error) {
      console.error('Error fetching conversion rate:', error);
    }
  };

  const fetchCryptoInfo = async () => {
    try {
      const response = await axios.get(`https://api.coingecko.com/api/v3/coins/${crypto.value}`);
      setCryptoInfo(response.data);
    } catch (error) {
      console.error('Error fetching crypto info:', error);
    }
  };

  const handleCryptoChange = (selectedOption: any) => {
    setCrypto(selectedOption);
  };

  const handleFiatChange = (selectedOption: any) => {
    setFiat(selectedOption);
  };

  const handleCryptoAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCryptoAmount(Number(event.target.value));
  };

  const handleExchange = () => {
    const tempCrypto = crypto;
    const tempFiat = fiat;
    setCrypto(tempFiat);
    setFiat(tempCrypto);
    setCryptoAmount(fiatAmount);
    setFiatAmount(cryptoAmount);
  };

  const cleanDescription = (html: string) => {
    return DOMPurify.sanitize(html);
  };

  const formatAmount = (amount: number) => {
    return amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount);
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Crypto Converter" />
      <div className="p-4 md:p-6 bg-custom-background rounded-sm border border-stroke shadow-default">
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2" htmlFor="crypto">
            Select Cryptocurrency:
          </label>
          <Select
            id="crypto"
            value={crypto}
            onChange={handleCryptoChange}
            options={cryptoOptions}
            className="block w-full"
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: '#ffffff',
                borderColor: 'rgba(255, 255, 255, 0.5)',
                color: 'black',
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isSelected ? '#4a5568' : isFocused ? '#2d3748' : '#6b7280',
                color: 'white',
              }),
              singleValue: (styles) => ({
                ...styles,
                color: 'black',
              }),
            }}
          />
        </div>

        <div className="mb-4">
          <label className="text-white text-sm font-bold mb-2" htmlFor="fiat">
            Select Fiat Currency:
          </label>
          <Select
            id="fiat"
            value={fiat}
            onChange={handleFiatChange}
            options={fiatOptions}
            className="block w-full"
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: '#ffffff',
                borderColor: 'rgba(255, 255, 255, 0.5)',
                color: 'black',
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isSelected ? '#4a5568' : isFocused ? '#2d3748' : '#6b7280',
                color: 'white',
              }),
              singleValue: (styles) => ({
                ...styles,
                color: 'black',
              }),
            }}
          />
        </div>

        <div className="flex items-center mb-4">
          <div className="w-full">
            <label className="text-white text-sm font-bold mb-2" htmlFor="cryptoAmount">
              Amount of {crypto.label}:
            </label>
            <input type="number" id="cryptoAmount" value={cryptoAmount} onChange={handleCryptoAmountChange} className="block w-full bg-white border border-gray-300 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-black" />
          </div>
          <button onClick={handleExchange} className="ml-4 p-2 text-white bg-blue-500 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline">
            <FaExchangeAlt />
          </button>
        </div>

        <div className="mb-4">
          <label className="text-white text-sm font-bold mb-2">
            Equivalent in {fiat.label}:
          </label>
          <p className="text-lg font-semibold">{formatCurrency(fiatAmount, fiat.value.toUpperCase())}</p>
        </div>

        <Link to={`/dashboard/tables/}`} className="mt-4 block md:inline-block bg-blue-500 text-white font-bold py-2 px-4 rounded text-center">
          Buy {crypto.label}
        </Link>

        {cryptoInfo.name && (
          <div className="mt-6">
            <h3 className="text-2xl font-bold mb-2 text-white">{cryptoInfo.name}</h3>
            <img src={cryptoInfo.image?.large} alt={cryptoInfo.name} className="w-20 h-20 mb-4 rounded-full shadow-md mx-auto" />
            <p><strong>Market Cap:</strong> {formatCurrency(cryptoInfo.market_data?.market_cap?.usd ?? 0, 'USD')}</p>
            <p><strong>Volume (24Hrs):</strong> {formatCurrency(cryptoInfo.market_data?.total_volume?.usd ?? 0, 'USD')}</p>
            <p><strong>Supply:</strong> {formatAmount(cryptoInfo.market_data?.circulating_supply ?? 0)}</p>
            <div dangerouslySetInnerHTML={{ __html: cleanDescription(cryptoInfo.description?.en || '') }} className="prose prose-sm mt-4 text-white"></div>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
};

export default Profile;






