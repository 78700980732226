import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BitcoinDominanceChart: React.FC = () => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const fetchBitcoinDominance = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/global');
        const data = await response.json();
        const btcDominance = data.data.market_cap_percentage.btc;

        if (chartRef.current) {
          new Chart(chartRef.current, {
            type: 'doughnut',
            data: {
              labels: ['Bitcoin', 'Others'],
              datasets: [{
                label: 'Bitcoin Dominance',
                data: [btcDominance, 100 - btcDominance],
                backgroundColor: ['#FFCE56', '#36A2EB'],
                hoverBackgroundColor: ['#FFCE56', '#36A2EB'],
              }]
            },
          });
        }
      } catch (error) {
        console.error('Error fetching Bitcoin dominance data:', error);
      }
    };

    fetchBitcoinDominance();
  }, []);

  return (
    <div className="p-4 bg-white dark:bg-boxdark rounded-sm shadow-default">
      <h4 className="mb-4 text-lg font-semibold text-black dark:text-white">
        Bitcoin Dominance
      </h4>
      <canvas ref={chartRef} width="400" height="400" />
    </div>
  );
};

export default BitcoinDominanceChart;







