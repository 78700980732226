import { Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import Coin from './Pages/Coin/Coin.jsx';
import CoinDetails from './Pages/Dashboard/pages/Explore/CoinDetails.tsx';
import BlogArticle from './Pages/BlogArticle'; // Add the import for BlogArticle
import Navbar  from './components/Navbar.jsx'; // Change the import statement
import Hero from '././components/Hero.jsx';
import TopCoins from './components/CryptoCurrencies/TopCoins.jsx'
import OurFeatures from './components/OurFeatures';
import DailyPrices from './Pages/DailyPrices.js'; // Corrected import path
import Accordion from './components/Accordion';
import Newsletter from './components/Newsletter'; 
import Footer from './components/Footer';
import TopBlogs from './components/TopBlogs.jsx';
import Blogs from './Pages/Blogs.tsx';
import AboutUs from './Pages/AboutUs';
import BuyCryptoPage from './Pages/BuyCryptoPage';
import DigitalAssetsDisclosure from './Pages/DigitalAssetsDisclosure';
import KYC from './Pages/KYC.jsx';
import HelpCenter from './Pages/HelpCenter/HelpCenter.jsx';
import Tutorials from './Pages/HelpCenter/Tutorials.jsx';
import FAQs from './Pages/HelpCenter/FAQs';
import GettingStarted from './Pages/HelpCenter/GettingStarted.jsx';
import ComplianceRequirements from './Pages/ComplianceRequirements.jsx';
import FAQ from './Pages/FAQ';
import GetStarted from './Pages/GetStarted';
import Institutional from './Pages/Institutional';
import Institutions from './components/Institutions.jsx';
import Investors from './Pages/Investors';
import Legal from './Pages/Legal';
import TermsOfUse from './Pages/TermsOfUse.jsx';
import Research from './Pages/Research';
import SellCrypto from './Pages/SellCrypto';
import ContactUs from './Pages/ContactUs.jsx';
import Feedback from './Pages/Feedback.jsx';
import SwopCrypto from './Pages/SwopCrypto';
import SignInPage from './Pages/SignIn.jsx';
import CryptoCurrencies from './Pages/Dashboard/pages/CryptoCurrencies.tsx';
import OpenAccount from './components/OpenAccount.jsx';
import PageNotFound from './Pages/PageNotFound.jsx';
import Dashboard from './Pages/Dashboard/Dashboard.tsx';
import { ThirdwebProvider } from "thirdweb/react";
import { MoonPayProvider } from '@moonpay/moonpay-react';
import './index.css';
import BuyComponent from './components/BuyComponent.jsx'
import ProtectedRoute from './components/ProtectedRoute.jsx';
import { AuthContextProvider } from './Context/AuthContext.js';
import { SearchProvider } from './SearchContext.tsx';


export default function App() {
  return (
    <AuthContextProvider>
    <ThirdwebProvider>
    <SearchProvider>
    <MoonPayProvider apiKey="pk_test_tJAfw3DL3ujvh2QQAcAt1G8WRNbz2" environment="sandbox" debug>
      <Navbar />
      <div>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/buy-crypto" element={<ProtectedRoute element={<BuyCryptoPage />} />} />
          <Route path="/coins/*" element={<DailyPrices />} /> {/* Corrected route */}
          <Route path="/coins/:coinId" element={<CoinPage />} /> {/* Updated route for individual coins */}
          <Route path="/digital-assets-disclosure" element={<DigitalAssetsDisclosure />} />
          <Route path="/KYC" element={<KYC />} />
          <Route path="/Compliance-Requirements" element={<ComplianceRequirements />} />
          <Route path="/Help-Center/Getting-Started" element={<GettingStarted />} />
          <Route path="/Help-Center/Tutorials" element={<Tutorials />} />
          <Route path="/Help-Center/FAQs" element={<FAQs />} />
          <Route path="/Help-Center" element={<HelpCenter />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="sign-in" element={<SignInPage />} />
          <Route path="sign-up" element={<GetStarted />} />
          <Route path="/institutional" element={<Institutional />} />
          <Route path="/investors" element={<Investors />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/research" element={<Research />} />
          <Route path="/sell-crypto" element={<ProtectedRoute element={<SellCrypto />} />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/legal/terms-of-use" element={<TermsOfUse />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/swop-crypto" element={<ProtectedRoute element={<SwopCrypto />} />} />
          <Route path="/dashboard/coins/*" element={<CryptoCurrencies />} />
          <Route exact path="/dashboard/cryptocurrencies/" component={CryptoCurrencies} />
          <Route path="/coin/:id" component={CoinDetails} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:slug" element={<BlogArticle />} /> {/* Update the component to BlogArticle */}
          <Route path="/dashboard/*" element={<ProtectedRoute element={<Dashboard />} />} />
          <Route path="*" element={<PageNotFound />} /> {/* Added a catch-all route for unmatched URLs */}
        </Routes>
        <Footer />
      </div>
    </MoonPayProvider>
    </SearchProvider>

    </ThirdwebProvider>
    </AuthContextProvider>
  );
}

function HomePage() {
  return (
    <>
      <Hero />
      <BuyComponent />
      <TopCoins />
      <OpenAccount />
      <TopBlogs />
      <Analytics />
      <OurFeatures />
      <Institutions/>
      <Accordion />
      <Newsletter />
    </>
  );
}

function CoinPage() { // Added CoinPage component to render Coin.jsx
  return (
    <Coin />
  );
}
