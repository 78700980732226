import React from 'react';
import Heading from '../ui/PageUI/Heading';
import Subheading from '../ui/PageUI/SubHeading';
import Button from '../components/Button';
import Description from '../ui/PageUI/Description';
import FeatureCard from '../ui/PageUI/FeatureCard';
import AboutImage from '../Assets/about.png';
import Paragraph from '../ui/PageUI/Paragraph';
import Intro from '../ui/PageUI/Intro';
import ImageWithText from '../ui/PageUI/ImageWithText';
import imageSrc from '../Assets/Legal.png';

const links = [
  { name: 'Open roles', href: '#' },
  { name: 'Internship program', href: '#' },
  { name: 'Our vision', href: '#' },
  { name: 'Meet our leadership', href: '#' },
];

const stats = [
  { name: 'Liquidity Partners Worldwide', value: '12' },
  { name: 'Cryptocurrencies Availible', value: '300+' },
  { name: 'Regulation First Approach', value: '4+' },
  { name: 'Avalible in 25+ Countries', value: '25+' },
];

const AboutUs = () => {
  return (
    
    <div className="relative overflow-hidden py-12 sm:py-24">
      <div className="container mx-auto px-6 lg:px-8 flex flex-col md:flex-row items-center justify-center">
        <div className="md:w-1/2 md:pr-10">
          <Heading text="About Laani" />
          <Subheading text="Crypto Accessibility: Invest Simply, Securely" />
          <Intro
            text="Investing in the future shouldn't feel complex. At Laani, we believe everyone deserves the opportunity to participate in the exciting world of cryptocurrencies. That's why we built a user-friendly platform that removes the jargon and complexity often associated with crypto investing. We focus on what matters most - making it easy and secure for you to buy, sell, and manage your digital assets. Our intuitive interface and educational resources empower you to confidently navigate the crypto market, even if you're a complete beginner. Join Laani and experience crypto investing simplified."
          />
          <Button text="Get Started" onClick={() => alert('Button Clicked!')} className="mt-4 mx-auto md:mx-0" />
        </div>
        <br></br>
        <div className="md:w-1/2 md:pl-10 mt-8 md:mt-0">
          <img className="w-full h-auto rounded-lg" src={AboutImage} alt="About Laani" />
        </div>
      </div>

      <Description text="Here's how we're making a difference:" />
      <div className="container mx-auto px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <FeatureCard
            title="Financial Literacy"
            description="We offer a wealth of educational resources to help users understand cryptocurrencies and make informed investment decisions."
          />
          <FeatureCard
            title="Accessibility"
            description="Our platform is designed for everyone, regardless of experience level. We offer a variety of tools and features to simplify buying, selling, and managing crypto assets."
          />
          <FeatureCard
            title="Building Wealth"
            description="We provide a secure and reliable environment for you to invest in crypto and potentially grow your wealth over time."
          />
        </div>
      </div>

      <div className="container mx-auto px-6 lg:px-8 mt-12">
        <ImageWithText
          heading="Building Trust Through Compliance"
          paragraph="At Laani, we understand that security and compliance are paramount for building trust in the crypto space. That's why we prioritize a robust compliance framework that protects both our users and the broader crypto ecosystem. We invite you to explore the resources below for a deeper understanding of our compliance practices:"
          bullets={[
            { text: 'Know-Your-Customer (KYC) verification', link: '/KYC' },
            { text: 'Compliance Requirements', link: '/Compliance-Requirements' },
            { text: 'Digital Asset Disclosures', link: '/Digital-Assets-Disclosure' },
          ]}
          imageUrl={imageSrc}
        />
      </div>
      <div className="mx-auto max-w-2xl mt-12">

      <Paragraph
        heading="Our Mission"
        text="Our mission is to unlock the transformative potential of cryptocurrencies, empowering individuals and institutions to build a more inclusive and prosperous financial future. We believe crypto fosters economic freedom by dismantling barriers to entry and creating a transparent, accessible financial system. Through a user-friendly platform and commitment to education, we strive to onboard over 1 billion people into the crypto economy, enabling them to participate, invest, and build wealth."
      />
      </div>


      <div className="mx-auto max-w-2xl mt-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white-600 sm:grid-cols-2 md:flex lg:gap-x-10">
          {links.map((link) => (
            <a key={link.name} href={link.href} className="hover:underline">
              {link.name} <span aria-hidden="true">&rarr;</span>
            </a>
          ))}
        </div>
        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
          {stats.map((stat) => (
            <div key={stat.name} className="flex flex-col-reverse">
              <dt className="text-base leading-7 text-white-600">{stat.name}</dt>
              <dd className="text-2xl font-bold leading-9 tracking-tight text-white-900">{stat.value}</dd>
            </div>
          ))}
        </dl>
      </div>

      <div className="text-center mt-8">
        <a href="/vision" className="text-blue-600 hover:underline">Our Vision</a>
      </div>
    </div>
  );
};

export default AboutUs;










