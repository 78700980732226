import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import TableTwo from '../components/Tables/TableTwo';
import CoinDetails from './Explore/CoinDetails';
import Watchlist from './Watchlist/Watchlist';
import DefaultLayout from '../layout/DefaultLayout';

type Crypto = {
  id: string;
  name: string;
  image: string;
  current_price: number;
  price_change_percentage_24h: number;
  market_cap: number;
};

const CryptoCurrencies: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [watchlist, setWatchlist] = useState<string[]>([]);
  const [cryptos, setCryptos] = useState<Crypto[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCoinClick = (coinId: string) => {
    navigate(`/dashboard/coins/${coinId}`);
  };

  const handleAddToWatchlist = (coinId: string) => {
    setWatchlist(prevWatchlist => [...prevWatchlist, coinId]);
  };

  const handleRemoveFromWatchlist = (coinId: string) => {
    setWatchlist(prevWatchlist => prevWatchlist.filter(id => id !== coinId));
  };

  useEffect(() => {
    const fetchCryptoData = async (page: number) => {
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/coins/markets',
          {
            params: {
              vs_currency: 'usd',
              order: 'market_cap_desc',
              per_page: 10,
              page,
              sparkline: false,
            },
          }
        );
        setCryptos(response.data);
      } catch (error) {
        console.error('Error fetching crypto data:', error);
      }
    };

    fetchCryptoData(currentPage);
  }, [currentPage]);

  return (
    <DefaultLayout>
      <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        <div className="col-span-12 xl:col-span-18">
          <div className="flex justify-end mb-4">
            {location.pathname === '/dashboard/coins' ? (
              <Link to="/dashboard/coins/watchlist" className="bg-blue-500 text-white px-4 py-2 rounded">
                My Watchlist
              </Link>
            ) : (
              <Link to="/dashboard/coins" className="bg-blue-500 text-white px-4 py-2 rounded">
                Crypto Currencies
              </Link>
            )}
          </div>
          <Routes>
            <Route
              path="/"
              element={
                <TableTwo
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  onCoinClick={handleCoinClick}
                  watchlist={watchlist}
                  onAddToWatchlist={handleAddToWatchlist}
                  onRemoveFromWatchlist={handleRemoveFromWatchlist}
                  cryptos={cryptos}
                />
              }
            />
            <Route path="/coin/:id" element={<CoinDetails />} />
            <Route
              path="/watchlist"
              element={
                <Watchlist
                  watchlist={watchlist}
                  cryptos={cryptos}
                  onRemoveFromWatchlist={handleRemoveFromWatchlist}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default CryptoCurrencies;



