import React, { useState } from 'react';
import { MoonPayProvider, MoonPaySellWidget } from '@moonpay/moonpay-react'; // Import MoonPay components

const SellCryptoPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSignUp = () => {
    // Handle sign-up logic
    setIsLoggedIn(true);
  };

  const handleSignIn = () => {
    // Handle sign-in logic
    setIsLoggedIn(true);
  };

  const onClickTabItem = () => {
    // Handle click event, if necessary
  };

  return (
    <div className="sell-crypto-page">
      <header className="page-header">
        <h1 className="page-title">Sell Crypto</h1>
        <p className="page-description">
          Sign up or sign in to sell cryptocurrency instantly using MoonPay.
        </p>
      </header>

      {/* Sign up or sign in prompt */}
      {!isLoggedIn && (
        <div className="sign-up-sign-in-prompt">
          <p className="prompt-text">Please sign up or sign in to sell crypto:</p>
          <button className="sign-up-button" onClick={handleSignUp}>Sign Up</button>
          <button className="sign-in-button" onClick={handleSignIn}>Sign In</button>
        </div>
      )}

      {/* MoonPay Buy Crypto Button */}
      {isLoggedIn && (
        <>
          <div className="button-wrapper">
            <button
              onClick={() => onClickTabItem('Sell Crypto')}
              className="sell-crypto-button"
            >
              Sell Crypto
            </button>
          </div>
          {/* MoonPay Buy Widget */}
          <MoonPayProvider apiKey="pk_test_tJAfw3DL3ujvh2QQAcAt1G8WRNbz2" environment="sandbox" debug>
            <MoonPaySellWidget
              variant="overlay"
              baseCurrencyCode="eth"
              baseCurrencyAmount="0.1"
              quoteCurrencyCode="usd"
              visible
              style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px', marginTop: '10px' }}
            />
          </MoonPayProvider>
        </>
      )}
    </div>
  );
};

// CSS styles for the SellCrypto page
const SellCryptoPageStyles = `
  .sell-crypto-page {
    color: var(--primary);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page-header {
    margin-bottom: 2rem;
    text-align: center;
  }

  .page-title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .page-description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .sign-up-sign-in-prompt {
    margin-bottom: 2rem;
  }

  .prompt-text {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .sign-up-button,
  .sign-in-button {
    padding: 0.8rem 2rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 0.5rem;
    background-color: var(--blue);
    color: var(--primary);
    cursor: pointer;
    margin-right: 1rem;
  }

  .sign-up-button:hover,
  .sign-in-button:hover {
    background-color: var(--text-blue);
  }

  .button-wrapper {
    margin-bottom: 2rem; // Add margin bottom to separate from MoonPay widget
  }

  .sell-crypto-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    border: none;
    border-radius: 0.5rem;
    background-color: var(--blue);
    color: var(--primary);
    cursor: pointer;
  }

  .sell-crypto-button:hover {
    background-color: var(--text-blue);
  }
`;

// Apply the styles to the SellCryptoPage component
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = SellCryptoPageStyles;
document.head.appendChild(styleSheet);

export default SellCryptoPage;
