import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { addToWatchlist, removeFromWatchlist } from '../../../../Firebase';
import { useAuth } from '../../../../Context/AuthContext'; // Assuming you have an AuthContext

type Crypto = {
  id: string;
  name: string;
  image: string;
  current_price: number;
  price_change_percentage_24h: number;
  market_cap: number;
};

type TableTwoProps = {
  currentPage: number;
  onPageChange: (page: number) => void;
  onCoinClick: (coinId: string) => void;
  watchlist: string[];
  onAddToWatchlist: (coinId: string) => void;
  onRemoveFromWatchlist: (coinId: string) => void;
  cryptos: Crypto[];
};

const TableTwo: React.FC<TableTwoProps> = ({
  currentPage,
  onPageChange,
  onCoinClick,
  watchlist,
  onAddToWatchlist,
  onRemoveFromWatchlist,
}) => {
  const { currentUser } = useAuth(); // Get the current user from your AuthContext
  const [cryptos, setCryptos] = useState<Crypto[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  // Fetch crypto data from API
  const fetchCryptoData = async (page: number) => {
    try {
      const response = await axios.get(
        'https://api.coingecko.com/api/v3/coins/markets',
        {
          params: {
            vs_currency: 'usd',
            order: 'market_cap_desc',
            per_page: 10,
            page,
            sparkline: false,
          },
        }
      );
      setCryptos(response.data);
      setTotalPages(Math.ceil(300 / 10)); // Assuming you have a fixed number of pages
    } catch (error) {
      console.error('Error fetching crypto data:', error);
    }
  };

  useEffect(() => {
    fetchCryptoData(currentPage);
  }, [currentPage]);

  // Format price for display
  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  // Format market cap for display
  const formatMarketCap = (marketCap: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      compactDisplay: 'short',
    }).format(marketCap);
  };

  // Add coin to watchlist
  const handleAddToWatchlist = async (coinId: string) => {
    if (!currentUser) return;
    try {
      await addToWatchlist(currentUser.uid, coinId);
      onAddToWatchlist(coinId); // Update local state immediately
    } catch (error) {
      console.error('Error adding to watchlist:', error);
    }
  };

  // Remove coin from watchlist
  const handleRemoveFromWatchlist = async (coinId: string) => {
    if (!currentUser) return;
    try {
      await removeFromWatchlist(currentUser.uid, coinId);
      onRemoveFromWatchlist(coinId); // Update local state immediately
    } catch (error) {
      console.error('Error removing from watchlist:', error);
    }
  };

  // Render pagination buttons
  const renderPagination = () => {
    const pages = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (currentPage <= 2) {
      endPage = 4;
    }
    if (currentPage >= totalPages - 1) {
      startPage = totalPages - 3;
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === i ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <div className="rounded-sm border border-stroke  px-2 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-6 xl:px-8 xl:pb-1 w-full">
      <h4 className="mb-4 text-lg font-semibold text-white dark:text-white sm:mb-6 sm:text-xl">
        Cryptocurrency Prices by Market Cap
      </h4>

      <div className="overflow-x-auto">
        <div className="min-w-full grid grid-cols-8 text-xs sm:text-sm gap-x-2 rounded-sm bg-black-2 dark:bg-meta-4">
          <div className="p-2 sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-white">#</h5>
          </div>
          <div className="p-2 sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-white">Coin</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-white">Name</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-white">Price (USD)</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-white">24h Change</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-white">Market Cap</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-white">Actions</h5>
          </div>
          <div className="p-2 text-center sm:p-3 xl:p-4">
            <h5 className="font-medium uppercase text-white">Add to Watchlist</h5>
          </div>
        </div>

        {cryptos.map((crypto, index) => (
          <div
            key={crypto.id}
            className={`min-w-full grid grid-cols-8 text-xs sm:text-sm gap-x-2 ${
              index === cryptos.length - 1 ? '' : 'border-b border-stroke dark:border-strokedark'
            }`}
            onClick={() => onCoinClick(crypto.id)}
            style={{ cursor: 'pointer' }}
          >
            <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
              <p className="text-white dark:text-white">{(currentPage - 1) * 10 + index + 1}</p>
            </div>
            <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
              <div className="flex-shrink-0">
                <img src={crypto.image} alt={crypto.name} className="h-6 w-6 sm:h-8 sm:w-8" />
              </div>
            </div>
            <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
              <p className="text-white dark:text-white">{crypto.name}</p>
            </div>
            <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4 whitespace-nowrap">
              <p className="text-white dark:text-white">{formatPrice(crypto.current_price)}</p>
            </div>
            <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
              <p className={crypto.price_change_percentage_24h >= 0 ? 'text-green-500' : 'text-red-500'}>
                {crypto.price_change_percentage_24h.toFixed(2)}%
              </p>
            </div>
            <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
              <p className="text-white dark:text-white">{formatMarketCap(crypto.market_cap)}</p>
            </div>
            <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
              <a href="/dashboard/tables" className="bg-blue-500 text-white py-1 px-2 sm:px-3 rounded text-xs sm:text-sm">
                Buy
              </a>
            </div>
            <div className="flex items-center justify-center p-2 sm:p-3 xl:p-4">
              {watchlist.includes(crypto.id) ? (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveFromWatchlist(crypto.id);
                  }}
                  className="bg-red-500 text-white px-3 py-1 rounded"
                >
                  Remove
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToWatchlist(crypto.id);
                  }}
                  className="bg-green-500 text-white px-3 py-1 rounded"
                >
                  Add
                </button>
              )}
            </div>
          </div>
        ))}

        <div className="flex justify-center mt-4">{renderPagination()}</div>
      </div>
    </div>
  );
};

export default TableTwo;













