import React from 'react';
import styled from 'styled-components';

const ResearchPage = () => {
  return (
    <PageContainer>
      <PageHeader>Research</PageHeader>
      <ResearchList>
        <ResearchItem>
          <ResearchTitle>Market Analysis</ResearchTitle>
          <ResearchDescription>Stay informed with in-depth market analysis and insights.</ResearchDescription>
        </ResearchItem>
        <ResearchItem>
          <ResearchTitle>Trading Strategies</ResearchTitle>
          <ResearchDescription>Learn effective trading strategies from industry experts.</ResearchDescription>
        </ResearchItem>
        <ResearchItem>
          <ResearchTitle>Blockchain Research</ResearchTitle>
          <ResearchDescription>Explore the latest developments and innovations in blockchain technology.</ResearchDescription>
        </ResearchItem>
        {/* Add more research items as needed */}
      </ResearchList>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  padding: 20px;
`;

const PageHeader = styled.h1`
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
`;

const ResearchList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

const ResearchItem = styled.div`
  padding: 20px;
  border-radius: 8px;
`;

const ResearchTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ResearchDescription = styled.p`
  font-size: 16px;
`;

export default ResearchPage;
