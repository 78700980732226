import React from 'react';
import { Link } from 'react-router-dom';
import Heading from '../ui/PageUI/Heading';
import Subheading from '../ui/PageUI/SubHeading';
import Description from '../ui/PageUI/Description';
import FeatureCard from '../ui/PageUI/FeatureCard';
import AboutImage from '../Assets/legal1.png';
import Paragraph from '../ui/PageUI/Paragraph';
import ImageWithText from '../ui/PageUI/ImageWithText';
import imageSrc from '../Assets/Legal.png';
import Button from '../components/Button';

const Legal = () => {
  return (
    <div className="container mx-auto p-8 text-center">
      <Heading text="Legal and Compliance" />
      <Subheading text="View our agreements and other legal resources" />
      <img className="w-half h-auto my-8" src={AboutImage} alt="About Laani" />
      <Description text="Explore our legal documents and compliance information:" />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <FeatureCard 
          title="Terms of Use" 
          description="Understand the terms and conditions for using our platform."
          link={<Button><Link to="/legal/terms-of-use">Read More</Link></Button>} 
        />
        <FeatureCard 
          title="Arbitration Agreement" 
          description="Review the process for resolving disputes through arbitration."
          link={<Button><Link to="/legal/arbitration-agreement">Read More</Link></Button>} 
        />
        <FeatureCard 
          title="Privacy" 
          description="Learn how we protect your personal information and data."
          link={<Button><Link to="/legal/privacy">Read More</Link></Button>} 
        />
        <FeatureCard 
          title="Security" 
          description="Discover the measures we take to ensure the security of your assets."
          link={<Button><Link to="/legal/security">Read More</Link></Button>} 
        />
        <FeatureCard 
          title="General Risk Warning" 
          description="Understand the risks associated with investing in cryptocurrencies."
          link={<Button><Link to="/legal/general-risk-warning">Read More</Link></Button>} 
        />
        <FeatureCard 
          title="Prohibited Use Policy" 
          description="You shall use the Site or Platform solely in compliance with these Terms, solely for your own Account or your internal business purposes."
          link={<Button><Link to="/legal/prohibited-use-policy">Read More</Link></Button>} 
        />
      </div>
      <div className="container mx-auto px-8">
        <ImageWithText
          heading="Building Trust Through Compliance"
          paragraph="At Laani, we understand that security and compliance are paramount for building trust in the crypto space. That's why we prioritize a robust compliance framework that protects both our users and the broader crypto ecosystem.
          We invite you to explore the resources below for a deeper understanding of our compliance practices:"
          bullets={[
            { text: "Know-Your-Customer (KYC) verification", link: "/KYC" },
            { text: "Compliance Requirements", link: "/Compliance-Requirements" },
            { text: "Digital Asset Disclosures", link: "/Digital-Assets-Disclosure" },
          ]}
          imageUrl={imageSrc}
        />
      </div>
      <Paragraph 
        heading="Our Mission"
        text="Our mission is to unlock the transformative potential of cryptocurrencies, empowering individuals and institutions to build a more inclusive and prosperous financial future. We believe crypto fosters economic freedom by dismantling barriers to entry and creating a transparent, accessible financial system. Through a user-friendly platform and commitment to education, we strive to onboard over 1 billion people into the crypto economy, enabling them to participate, invest, and build wealth."
      />
    </div>
  );
};

export default Legal;




