import React from 'react';
import { useParams } from 'react-router-dom';
import { fullBlog } from '../app/blogs/lib/interface';
import { client, urlFor } from '../app/blogs/lib/sanity';
import { PortableText } from '@portabletext/react';
import { Img } from 'react-image';

export const revalidate = 30;

async function getData(slug: string) {
  const query = `
    *[_type == "blog" && slug.current == '${slug}'] {
        "currentSlug": slug.current,
          title,
          content,
          titleImage
      }[0]`;

  const data = await client.fetch(query);
  return data;
}

export default function BlogArticle() {
  const { slug } = useParams<{ slug?: string }>(); // Add "?" to make slug parameter optional

  const [data, setData] = React.useState<fullBlog | null>(null);

  React.useEffect(() => {
    async function fetchData() {
      if (slug) {
        const blogData: fullBlog = await getData(slug);
        setData(blogData);
      }
    }
    fetchData();
  }, [slug]);

  if (!data) {
    return <div className="text-center text-white">Loading...</div>;
  }

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        {/* Blog Title and Subtitle */}
        <h1 className="text-center text-4xl font-bold text-white mb-4">
          {data.title}
        </h1>
        <p className="text-center text-lg text-gray-400 mb-8">
          A deep dive into our latest blog post
        </p>

        {/* Blog Image */}
        <div className="flex justify-center mb-0"> {/* Removed margin-bottom */}
          <div className="relative w-full max-w-3xl" style={{ paddingTop: '100%' }}>
            <Img
              src={urlFor(data.titleImage).url()}
              alt="Title Image"
              className="absolute inset-0 w-full h-full object-cover rounded-lg border border-gray-700 shadow-lg"
            />
          </div>
        </div>

        {/* Blog Content */}
        <div className="prose prose-light max-w-full mx-auto text-white mt-0"> {/* Removed margin-top */}
          <PortableText value={data.content} />
        </div>
      </div>
    </div>
  );
}



